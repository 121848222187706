<template>
  <div class="csn-button-loader"><Loading /></div>
</template>

<script>
import { BUTTON_LOADER } from '@/constants'
export default {
  name: BUTTON_LOADER,
  components: {
    Loading: () => import('@/components/atoms/Loading'),
  },
}
</script>
