<template>
  <div class="row csn-user-account csn-user-account-section" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <section class="user-info">
          <div class="row">
            <div class="col-md-6">
              <h2>{{ t('personal_data') }}</h2>
              <div class="section">
                <div class="label">
                  {{ t('email') }}
                </div>
                <div class="value">{{ USER_EMAIL }}</div>
              </div>
              <div class="section">
                <div class="label">
                  {{ t('sex') }}
                </div>
                <div class="value">{{ gender }}</div>
              </div>
              <div class="section">
                <div class="label">
                  {{ t('first_name') }}
                </div>
                <div class="value">
                  {{ PERSONAL_INFO[UserInfo.FIRST_NAME] }}
                </div>
              </div>
              <div class="section">
                <div class="label">
                  {{ t('last_name') }}
                </div>
                <div class="value">{{ PERSONAL_INFO[UserInfo.LAST_NAME] }}</div>
              </div>
              <div class="section">
                <div class="label">{{ t('birth_date') }}</div>
                <div class="value">
                  {{
                    `${PERSONAL_INFO[UserInfo.BIRTH_YEAR]}-${
                      PERSONAL_INFO[UserInfo.BIRTH_MONTH]
                    }-${PERSONAL_INFO[UserInfo.BIRTH_DATE]}`
                  }}
                </div>
              </div>
              <div class="section">
                <div class="label">{{ t('birth_country') }}</div>
                <div v-if="isCountryDictPending" class="csn-country-loader">
                  <ButtonLoader />
                </div>
                <div v-else class="value">
                  {{ countryDictionary[PERSONAL_INFO[UserInfo.BIRTH_COUNTRY]] }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h2>{{ t('address') }}</h2>
              <div class="section">
                <div class="label">{{ t('street_and_house_number') }}</div>
                <div class="value">{{ PERSONAL_INFO[UserInfo.ADDRESS] }}</div>
              </div>
              <div class="section">
                <div class="label">{{ t('zip_code') }}</div>
                <div class="value">{{ PERSONAL_INFO[UserInfo.ZIP_CODE] }}</div>
              </div>
              <div class="section">
                <div class="label">{{ t('city') }}</div>
                <div class="value">{{ PERSONAL_INFO[UserInfo.CITY] }}</div>
              </div>
              <div class="section">
                <div class="label">{{ t('country') }}</div>
                <div v-if="isCountryDictPending" class="csn-country-loader">
                  <ButtonLoader />
                </div>
                <div v-else class="value">
                  {{ countryDictionary[PERSONAL_INFO[UserInfo.COUNTRY]] }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ButtonLoader from '@/components/ButtonLoader'
import {
  USER_ACCOUNT,
  PERSONAL_INFO,
  UserInfo,
  Module,
  USER_EMAIL,
  Digit,
  ResponseState,
  VALUE,
  ITEMS,
  EMPTY_OBJECT,
} from '@/constants'
import {
  when,
  isString,
  indexBy,
  pipe,
  path,
  isArray,
  ifElse,
  always,
  map,
} from '@/helpers'
import { MainApi } from '@/api'

export default {
  name: USER_ACCOUNT,
  components: {
    ButtonLoader,
  },
  data: () => ({
    countryDictionary: null,
    isCountryDictPending: false,
  }),
  computed: {
    ...mapState(Module.USER, [PERSONAL_INFO, USER_EMAIL]),
    t() {
      return this.$createComponentTranslator(USER_ACCOUNT)
    },
    UserInfo() {
      return UserInfo
    },
    gender() {
      return PERSONAL_INFO[UserInfo.SEX] ? this.t('mrs') : this.t('mr')
    },
    birthDate() {
      const year = PERSONAL_INFO[UserInfo.BIRTH_YEAR]
      const month = when(
        (month) => isString(month) && month.length === Digit.ONE,
        (month) => `0${month}`,
      )(PERSONAL_INFO[UserInfo.BIRTH_MONTH])
      const date = when(
        (date) => isString(date) && date.length === Digit.ONE,
        (date) => `0${date}`,
      )(PERSONAL_INFO[UserInfo.BIRTH_DATE])

      return `${year}-${month}-${date}`
    },
  },
  async created() {
    try {
      this.isCountryDictPending = true
      const { data, state } = await MainApi.getCountryList()

      if (state === ResponseState.OK) {
        this.countryDictionary = pipe(
          path([ITEMS]),
          ifElse(
            isArray,
            pipe(
              indexBy(VALUE),
              map((item) => item.name),
            ),
            always(EMPTY_OBJECT),
          ),
        )(data)
      }

      this.isCountryDictPending = false
    } catch (e) {
      console.error(e)
    }
  },
}
</script>
